/******************************************************/
/****** Use relative rather than absolute values******/
/****************************************************/

/* /core/public/shared/assets/css/responsive.css */
.fly-out-menu-container .fly-out-menu-button .glyph-keyboard_arrow_down {
	font-size: 1.25rem;
}

/* core/website/modules/jobs/css/screen.css */
#jobs_module.cms_list .cms_list_item .cms_date h3 {
	font-size: 0.6875rem;
}

#jobs_module div.application-for-employment div.required_field_information {
	font-size: 0.75rem;
}

#jobs_module div.application-for-employment .all_fields_required_note {
	font-size: 0.6875rem;
}

#jobs_module div.application-for-employment form div.form-field label {
	font-size: 0.75rem;
}

#jobs_module div.application-for-employment form div.form-field label em,
#jobs_module div.application-for-employment .required {
	font-size: 0.75rem;
}

#jobs_module div.application-for-employment form div.form-field span.text input {
	font-size: 0.75rem;
}

#jobs_module div.application-for-employment form div.form-field span.textarea textarea {
	font-size: 0.75rem;
}

#jobs_module div.application-for-employment form div.form-field span label {
	font-size: 0.5625rem;
}

#jobs_module div.application-for-employment form div.form-field span.checkbox label {
	font-size: 0.75rem;
}

#jobs_module div.application-for-employment form div.form-field select {
	font-size: 0.75rem;
}

#jobs_module div.application-for-employment form div.form-field span small {
	font-size: 0.5625rem;
}

/* core/website/modules/submissionforms/css/print.css */
.module-forms.confirmation .step-title {
	font-size: 0.875rem;
}

/* core/website/modules/submissionforms/css/screen.css */
#submissionforms_module.cms_form .cms_hint {
	font-size: 0.6875rem;
}

#submissionforms_module.cms_form .cms_date_hint {
	font-size: 0.6875rem;
}

#submissionforms_module.cms_form .agreement_hint {
	font-size: 0.6875rem;
}

.module-forms.confirmation .step-title {
	font-size: 1rem;
}

#submissionforms_module.homepage_contentbox .cms_hint,
#submissionforms_module.homepage_contentbox .cms_date_hint {
	font-size: 0.6875rem;
}

#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.6875rem;
}

.module-forms .ui-form-field-payment-information,
.module-forms .ui-data-addresses h3 {
	font-size: 0.875rem;
}

/******************************************************/
/****** Ensure that colors have enough contrast ******/
/****************************************************/
/* /core/public/shared/assets/css/responsive.css */
.fly-out-menu-list-container ul.fly-out-menu-list li a:active,
.fly-out-menu-list-container ul.fly-out-menu-list li.selected a {
	background: #11406f !important;
	color: #ffffff !important;
}

/* /core/public/shared/assets/css/screen.css */
.search-facets .search-facet .price-range-button a {
	background-color: #4f4f4f;
	color: #ffffff;
}


/******************************************************/
/* Ensure information conveyed with color is available without color */
/****************************************************/
/* /core/public/shared/assets/css/responsive.css */
.pagination-wrapper .pagination-previous-page .disabled,
.pagination-wrapper .pagination-next-page .disabled {
	color: #333;
	font-style: italic;
}

/* /core/website/modules/accounts/assets/css/screen.css */
#account_orderhistory .order_section,
#account_addresses .address_section.current_cart {
	background-color: #f0f0f0;
	font-weight: bold;
}

/* core/website/modules/submissionforms/css/screen.css */
#submissionforms_module.cms_form .cms_steps_wrapper .active,
#submissionforms_module.cms_form .cms_steps_wrapper .last_active {
	background-color: #d1d0ce;
	font-style: italic;
}

#submissionforms_module.cms_form .container-buttons-wrapper span.container-button:hover,
#submissionforms_module.homepage_contentbox .container-buttons-wrapper span.container-button:hover {
	background-color: hsla(90, 20%, 65%, 0.15);
	box-shadow: 0px 1px 4px #9ba694;
}

#submissionforms_module.cms_form .container-buttons-wrapper span.add:hover,
#submissionforms_module.homepage_contentbox .container-buttons-wrapper span.add:hover {
	color: #348300;
	box-shadow: 0px 1px 4px #9ba694;
}

#submissionforms_module.cms_form .container-buttons-wrapper span.remove:hover,
#submissionforms_module.homepage_contentbox .container-buttons-wrapper span.remove:hover {
	color: #d3121a;
	box-shadow: 0px 1px 4px #9ba694;
}

#submissionforms_module.cms_form .cms_buttons .cms_form_button:hover {
	background-color: #dddddd;
	cursor: pointer;
}

#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button:hover {
	background-color: #dddddd;
	cursor: pointer;
}
