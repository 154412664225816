body {
	font-family: 'Titillium Web', sans-serif;
	color: #333;
	background-color: #ffffff;
	font-size: 1rem;
	line-height: 1.5rem;
}

h1 {
	margin-top: 0px;
	margin-bottom: 1rem;
	font-family: 'Roboto Slab', sans-serif;
	color: #006e51;
	font-size: 2.5rem;
	line-height: 3rem;
	font-weight: 700;
}

h2 {
	margin-top: 0px;
	margin-bottom: 1rem;
	font-family: 'Roboto Slab', sans-serif;
	color: #006e51;
	font-size: 2rem;
	line-height: 2.5rem;
	font-weight: 700;
}

h3 {
	margin-top: 0px;
	margin-bottom: 1rem;
	font-family: 'Roboto Slab', sans-serif;
	color: #006e51;
	font-size: 1.75rem;
	line-height: 2.25rem;
	font-weight: 700;
}

h4 {
	margin-top: 0px;
	margin-bottom: 1rem;
	font-family: 'Roboto Slab', sans-serif;
	color: #006e51;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 700;
}

h5 {
	margin-top: 0px;
	margin-bottom: 1rem;
	font-family: 'Roboto Slab', sans-serif;
	color: #006e51;
	font-size: 1.35rem;
	line-height: 1.85rem;
	font-weight: 700;
}

h6 {
	margin-top: 0px;
	margin-bottom: 1rem;
	font-family: 'Roboto Slab', sans-serif;
	color: #006e51;
	font-size: 1.125rem;
	line-height: 1.625rem;
	font-weight: 700;
}

p {
	margin-top: 0px;
	margin-bottom: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
}

ul {
	margin-top: 0px;
	margin-bottom: 20px;
	padding-left: 40px;
}

ol {
	margin-top: 0px;
	margin-bottom: 20px;
	padding-left: 40px;
}

li {
	margin-bottom: 0.25rem;
}

strong {
	font-weight: bold;
}

blockquote {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	padding: 0.5rem 1rem;
	border-left: 5px solid #006e51;
	font-size: 1.2rem;
	line-height: 1.9rem;
}

figure {
	margin-bottom: 20px;
}

figcaption {
	margin-top: 7px;
	color: rgba(51, 51, 51, 0.56);
	font-size: 0.875rem;
	line-height: 20px;
	text-align: center;
}

.primary-button,
.primary_button {
	padding: 0.65rem 1rem;
	border: 2px solid #006e51;
	border-radius: 3px;
	background-color: #006e51;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Verdana, Geneva, sans-serif;
	color: #fff;
	font-size: 1rem;
	line-height: 1.25rem;
	font-weight: 600;
	text-align: center;
	text-decoration: none;
	border: 0px;
}

.primary-button:hover,
.primary_button:hover {
	background-color: #01503c;
	color: #fff;
}

.primary-button.w--current {
	background: #2e80b6;
}

.primary-button.white {
	margin-top: 0.5rem;
	margin-bottom: 0rem;
	padding-top: 0.4rem;
	padding-bottom: 0.4rem;
	border: 2px solid #fff;
	background-color: #fff;
	font-family: Verdana, Geneva, sans-serif;
	color: #5d5d5d;
	font-weight: 600;
	text-transform: capitalize;
}

.primary-button.white:hover {
	border-color: #e0e0e0;
	background-color: #e0e0e0;
}

.primary-button.white.submit-button {
	margin-top: 0.5rem;
	margin-left: 113px;
	float: left;
	border: 2px solid #006e51;
	background-color: #006e51;
}

.primary-button.white.submit-button:hover {
	background-color: #5d5d5d;
	color: #fff;
}

.main-nav-link {
	display: inline-block;
	padding: 1rem;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #006e51;
	font-size: 1.05rem;
	line-height: 1.05rem;
	font-weight: 600;
}

.main-nav-link:hover {
	box-shadow: inset 0 -3px 0 0 #fff;
	color: #000;
}

.section {
	position: relative;
	padding-top: 3rem;
	padding-bottom: 3rem;
	background-color: #fff;
}

.section.divisions-area {
	padding-top: 0rem;
	padding-bottom: 8rem;
	border-top: 10px solid #006e51;
	background-color: #f6f6f6;
	background-image: none;
	background-position: 0px 0px;
	background-size: auto;
	background-repeat: repeat;
}

.section.bottom-content-area {
	padding-top: 2rem;
	padding-bottom: 3.5rem;
	background-color: #f6f6f6;
	margin-top: -1px;
}

.section.products-section {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 0rem;
	padding-bottom: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
	background-image: -webkit-linear-gradient(270deg, transparent 59%, rgba(0, 0, 0, 0.4)), url('/20170713122722/assets/images/az-subtle_1az-subtle.png');
	background-image: linear-gradient(180deg, transparent 59%, rgba(0, 0, 0, 0.4)), url('/20170713122722/assets/images/az-subtle_1az-subtle.png');
	background-position: 0px 0px, 0px 0px;
	background-size: auto, 50px;
	background-repeat: repeat, repeat;
	background-attachment: scroll, scroll;
}

.cta-section {
	padding-top: 50px;
	padding-bottom: 50px;
	background-color: #dbdbdb;
}

.cta-section.centered-accented {
	padding: 2rem 0px;
	background-color: transparent;
	background-image: -webkit-linear-gradient(0deg, #006e51, #02271e);
	background-image: linear-gradient(90deg, #006e51, #02271e);
	color: #fff;
	text-align: center;
}

.cta-heading {
	margin-top: 0px;
	margin-right: 1.25rem;
	margin-bottom: 0px;
	font-family: 'Roboto Slab', sans-serif;
	font-size: 2rem;
	line-height: 2rem;
	font-weight: 400;
}

.slider-arrow-link {
	width: 60px;
	height: 60px;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	font-size: 1.75rem;
}

.slider-arrow-link:hover {
	opacity: 0.6;
}

.slider-arrow-link.right {
	right: 0px;
	bottom: 0px;
	z-index: 1;
	width: 60px;
	height: 60px;
	margin-bottom: 0px;
}

.slider-arrow-link.left {
	left: 0px;
	bottom: 0px;
	z-index: 1;
	margin-bottom: 0rem;
	color: #fff;
}

.slider-arrow-link.left:hover {
	opacity: 0.6;
}

.section-heading {
	margin-bottom: 20px;
}

.section-heading.centered {
	margin-bottom: 1rem;
	color: #006e51;
	font-size: 2.5rem;
	line-height: 3rem;
	font-weight: 700;
	text-align: center;
	letter-spacing: 0.1rem;
	text-transform: uppercase;
}

.section-heading.centered-white {
	margin-bottom: 1rem;
	color: #fff;
	font-size: 2.5rem;
	line-height: 3rem;
	font-weight: 400;
	text-align: center;
	letter-spacing: 0.1rem;
	text-transform: uppercase;
}

.footer {
	padding-top: 50px;
	padding-bottom: 50px;
}

.footer.accent {
	padding-top: 2.25rem;
	padding-bottom: 1.75rem;
	background-color: #333;
	clear: both;
	position: relative;
	z-index: 1;
}

.utility-page-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100vw;
	height: 100vh;
	max-height: 100%;
	max-width: 100%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.utility-page-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 260px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	text-align: center;
}

.utility-page-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.container,
.footer-container {
	position: relative;
	display: block;
	width: 100%;
	max-width: 1232px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 2rem;
	padding-left: 2rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.container.flex-horizontal,
.footer-container.flex-horizontal {
	position: relative;
	display: block;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.container.center-flex {
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.container.lower-home-container {
	padding-right: 6rem;
	padding-left: 6rem;
}

.container.signoff-container,
.footer-container.signoff-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.signoff {
	padding-top: 1.75rem;
	padding-bottom: 1.75rem;
	background-color: #5d5d5d;
	position: relative;
	z-index: 1;
}

.grip-subtext {
	display: inline;
	float: right;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #fff;
	font-size: 0.5rem;
	line-height: 8px;
	text-align: right;
	text-transform: uppercase;
}

.location-footer-text {
	margin-bottom: 1rem;
	color: #f1f1f1;
	font-size: 1rem;
	line-height: 1.4rem;
	font-weight: 400;
	text-align: left;
}

.footer-heading {
	margin-bottom: 1rem;
	color: #fff;
	font-size: 1rem;
	line-height: 1.75rem;
	text-align: left;
	text-transform: uppercase;
}

.footer-heading strong {
	font-weight: 600;
}

.list-item {
	padding-bottom: 8px;
	font-family: 'Titillium Web', sans-serif;
	color: #333;
}

.van-diest-supply-logo.w--current {
	display: inline-block;
	width: 5rem;
	height: 5rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.search-button {
	display: inline-block;
	width: 25%;
	height: 2.5rem;
	padding-top: 7px;
	float: right;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	background-color: #006e51;
	color: #fff;
	font-size: 0.9rem;
	letter-spacing: 0.04rem;
	text-transform: uppercase;
}

.search-button:hover {
	background-color: #01503c;
}

.unordered-list {
	margin-bottom: 1rem;
	font-family: Verdana, Geneva, sans-serif;
	color: #5d5d5d;
}

.half-div {
	display: inline-block;
	width: 46%;
	margin-top: 1.75rem;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	padding-right: 1rem;
	padding-left: 1rem;
}

.about-paragraph {
	font-size: 1.15rem;
	line-height: 1.65rem;
	text-align: left;
}

.column-25-percent {
	display: inline-block;
	width: 25%;
	height: auto;
	padding-left: 1rem;
	float: right;
}

.column-25-percent.no-padding {
	width: 48%;
	margin-right: 2%;
	padding-left: 0rem;
	float: left;
}

.phone-link,
.phone-link:hover {
	display: inline-block;
	margin-bottom: 1rem;
	color: #f1f1f1 !important;
	font-size: 1rem;
	line-height: 1.4rem;
	font-weight: 400;
	text-align: right;
}

a[href^="tel:"].phone-link {
	color: #f1f1f1 !important;
	font-size: 1rem;
	line-height: 1.4rem;
	font-weight: 400;
	text-align: right;
}

.email-link {
	display: inline-block;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #f1f1f1;
	font-size: 1rem;
	line-height: 1.75rem;
	font-weight: 600;
	text-align: left;
	text-decoration: underline;
}

.email-link:hover {
	color: #a8a8a8;
	text-decoration: none;
}

.grip-footer-logo {
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: inline-block;
	width: 130px;
	height: 28px;
	margin-top: 0.25rem;
	margin-right: 1rem;
	float: right;
	background-image: url('/20170713122722/assets/images/globalreach_knockout_fullwhite-optimized.svg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.copyright-text {
	display: inline-block;
	margin-right: 0.25rem;
	margin-bottom: 0.5rem;
	margin-left: 0.25rem;
	color: #fff;
	font-size: 0.85rem;
	line-height: 1.25rem;
	text-align: center;
}

.inside-body-section {
	padding-top: 2rem;
	border-bottom: 0px none transparent;
}

.nav-drop-icon {
	display: none;
	margin-top: 1.125rem;
	margin-right: 1rem;
	color: #fff;
}

.header-top-row {
	margin-bottom: 1.3rem;
}

.header-search-container {
	position: absolute;
	left: 0px;
	top: 100%;
	right: 0px;
	display: block;
	overflow: hidden;
	width: 1200px;
	height: 0px;
	margin-right: auto;
	margin-left: auto;
}

.search-form {
	height: 2.5rem;
}

input[type="text"].search-text-field {
	display: inline-block;
	width: 75%;
	height: 2.5rem;
	float: left;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

.footer-subhead {
	color: #a8a8a8;
	font-size: 1rem;
	line-height: 1.5rem;
}

.footer-subhead strong {
	font-weight: 600;
}

.nav-drop-list {
	position: absolute;
	left: 0px;
	top: 100%;
	margin-top: -3px;
	padding: 0.5rem;
	border-top: 3px solid #fff;
	color: #fff;
}

.nav-drop-list.w--open {
	margin-top: 0px;
	padding: 0.25rem 0rem 10px;
	border-top-style: none;
	border-top-width: 4px;
	background-color: rgba(0, 110, 81, 0.9);
	box-shadow: 0 -4px 0 0 #fff;
}

.divisions-column {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
	margin-right: 0%;
	margin-left: 0px;
	padding-bottom: 0px;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-color: #fff;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: repeat, no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-align: center;
}

.divisions-column:hover {
	background-color: #fff;
	box-shadow: 1px 0 5px 0 hsla(0, 0%, 66%, 0.8);
	opacity: 0.75;
}

.divisions-column.image-1 {
	width: 100%;
	height: 100%;
	margin: 0rem 0%;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: repeat, no-repeat;
}

.divisions-title {
	display: inline-block;
	width: 100%;
	padding-top: 7px;
	padding-bottom: 7px;
	background-color: transparent;
	background-image: -webkit-linear-gradient(0deg, rgba(0, 110, 81, 0.83), rgba(0, 31, 23, 0.83)), url('/20170713122722/assets/images/az-subtle_1az-subtle.png');
	background-image: linear-gradient(90deg, rgba(0, 110, 81, 0.83), rgba(0, 31, 23, 0.83)), url('/20170713122722/assets/images/az-subtle_1az-subtle.png');
	background-position: 0px 0px, 0px 0px;
	background-size: auto, 50px;
	color: #fff;
	font-size: 1.3rem;
	line-height: 2.25rem;
	font-weight: 400;
	text-transform: capitalize;
}

.divisions-title.gradient-2 {
	background-image: -webkit-linear-gradient(0deg, rgba(0, 110, 81, 0.83), rgba(0, 31, 23, 0.83)), url('/20170713122722/assets/images/az-subtle_1az-subtle.png');
	background-image: linear-gradient(90deg, rgba(0, 110, 81, 0.83), rgba(0, 31, 23, 0.83)), url('/20170713122722/assets/images/az-subtle_1az-subtle.png');
	background-position: 0px 0px, 0px 0px;
	background-size: auto, 50px;
}

.flex-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.flex-row.divisions {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 3rem;
	margin-bottom: 2rem;
}

.hero-slider {
	width: 100%;
	height: 100%;
	background-image: -webkit-linear-gradient(0deg, rgba(0, 110, 81, 0.83), rgba(1, 34, 26, 0.83));
	background-image: linear-gradient(90deg, rgba(0, 110, 81, 0.83), rgba(1, 34, 26, 0.83));
}

.slide-nav {
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 1;
	display: block;
	width: 900px;
	margin-right: auto;
	margin-left: auto;
	font-size: 0.7rem;
}

.arrow-left {
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.arrow-right {
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.arrow-right:hover {
	color: #a8a8a8;
}

.slide-2 {
	position: relative;
}

.signoff-link {
	display: inline-block;
	border-bottom: 1px solid transparent;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #dbdbdb;
	font-size: 0.85rem;
	line-height: 1.125rem;
}

.signoff-link:hover {
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.9);
	color: #fff;
	text-decoration: none;
}

.divider {
	margin-top: 1.75rem;
	border-right: 1px solid #dbdbdb;
}

.signoff-links-div {
	display: inline-block;
}

.embed-video {
	display: block;
	width: 715px;
	margin-top: 2rem;
	margin-right: auto;
	margin-left: auto;
	text-align: center;
}

.column-50-percent {
	width: 48%;
	float: left;
	padding-right: 1.5%;
	border-left: 1px none transparent;
}

.column-50-percent.middle-border {
	width: 52%;
	padding-left: 1.5%;
	float: right;
	border-left-style: solid;
	border-left-color: hsla(0, 0%, 66%, 0.5);
}

.slider-caption-div {
	position: absolute;
	right: 0px;
	bottom: 80px;
	z-index: 2;
	display: block;
	width: 55%;
	max-width: none;
	margin-right: auto;
	margin-left: auto;
	padding: 1.5rem 6vw 2rem 2.5rem;
	background-color: transparent;
	background-image: -webkit-linear-gradient(0deg, rgba(0, 110, 81, 0.83), rgba(1, 34, 26, 0.83));
	background-image: linear-gradient(90deg, rgba(0, 110, 81, 0.83), rgba(1, 34, 26, 0.83));
}

.slider-caption-div.black-option {
	background: rgba(0, 0, 0, 0.54);
}

.slider-heading {
	display: block;
	margin-bottom: 0.5rem;
	color: #fff;
	font-size: 2rem;
	line-height: 2.7rem;
	font-weight: 400;
	text-align: left;
	text-shadow: 1px 0 10px rgba(0, 0, 0, 0.5);
}

.linkedin-link-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 32px;
	margin-right: 0.25rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: opacity 200ms ease;
	transition: opacity 200ms ease;
	color: #f1f1f1;
}

.linkedin-link-block:hover {
	opacity: 0.6;
}

.linkedin-logo {
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.linkedin-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 1rem;
	margin-bottom: 0.5rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.divisions-image-wrap {
	display: block;
	height: 200px;
	margin-right: auto;
	margin-left: auto;
}

.divisions-image-wrap.photo-1 {
	display: none;
	background-image: url('/20170713122722/assets/images/VDSC-video-capture-ag-distribution.png');
	background-position: 51% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.styles {
	display: inline-block;
	margin-right: auto;
	margin-left: auto;
	width: 100%;
}

.styles-wrapper {
	padding: 4rem 6rem;
}

.header-link {
	display: inline;
	margin-bottom: 1rem;
	border-bottom: 1px solid #006e51;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Roboto Slab', sans-serif;
	color: #006e51;
	font-size: 2.35rem;
	line-height: 2.75rem;
	font-weight: 700;
}

.header-link:hover {
	border-bottom-color: transparent;
	color: #909090;
}

.h2-link {
	display: inline;
	margin-bottom: 1rem;
	border-bottom: 1px solid #006e51;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Roboto Slab', sans-serif;
	color: #006e51;
	font-size: 2rem;
	line-height: 2.5rem;
	font-weight: 700;
}

.h2-link:hover {
	border-bottom-color: transparent;
	color: #909090;
}

.h3-link {
	display: inline;
	margin-bottom: 1rem;
	border-bottom: 1px solid #006e51;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Roboto Slab', sans-serif;
	color: #006e51;
	font-size: 1.75rem;
	line-height: 2.25rem;
	font-weight: 700;
}

.h3-link:hover {
	border-bottom-color: transparent;
	color: #909090;
}

.h4-link {
	display: inline;
	margin-bottom: 1rem;
	border-bottom: 1px solid #006e51;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Roboto Slab', sans-serif;
	color: #006e51;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 700;
}

.h4-link:hover {
	border-bottom-color: transparent;
	color: #909090;
}

.h5-link {
	display: inline;
	margin-bottom: 1rem;
	border-bottom: 1px solid #006e51;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Roboto Slab', sans-serif;
	color: #006e51;
	font-size: 1.35rem;
	line-height: 1.75rem;
	font-weight: 700;
}

.h5-link:hover {
	border-bottom-color: transparent;
	color: #909090;
}

.h6-link {
	display: inline;
	margin-bottom: 1rem;
	border-bottom: 1px solid #006e51;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Roboto Slab', sans-serif;
	color: #006e51;
	font-size: 1.125rem;
	line-height: 1.5rem;
	font-weight: 700;
	text-align: center;
}

.h6-link:hover {
	border-bottom-color: transparent;
	color: #909090;
}

.intro-paragraph {
	margin-bottom: 1rem;
	font-family: 'Titillium Web', sans-serif;
	font-size: 1.3rem;
	line-height: 1.9rem;
	font-weight: 400;
}

.text-link {
	display: inline;
	margin-bottom: 1rem;
	border-bottom: 1px dotted #a8a8a8;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Titillium Web', sans-serif;
	color: #006e51;
	line-height: 1.5rem;
	font-weight: 400;
}

.text-link:hover {
	border-bottom-color: transparent;
	color: #a8a8a8;
}

.block-quote {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1.25rem;
	border-left-color: #006e51;
	background-color: #f3f3f3;
	font-family: Verdana, Geneva, sans-serif;
	font-size: 1.125rem;
	line-height: 1.65rem;
	font-weight: 400;
}

.secondary-button {
	display: inline-block;
	margin-right: 1rem;
	margin-bottom: 1rem;
	padding: 0.65rem 1rem;
	border: 2px solid #006e51;
	border-radius: 4px;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Verdana, Geneva, sans-serif;
	color: #006e51;
	font-weight: 400;
}

.secondary-button:hover {
	background-color: rgba(0, 110, 81, 0.5);
	color: #fff;
}

.table-embed {
	margin-bottom: 1rem;
}

.email-form {
	margin-bottom: 1rem;
}

.field-label {
	position: relative;
	display: inline-block;
	width: 15%;
	margin-right: 0.5rem;
	margin-bottom: 0px;
	font-weight: 400;
	text-align: right;
}

input.text-field {
	display: inline-block;
	width: 80% !important;
	margin-bottom: 0px !important;
}

.screen-message {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
}

.screen-message.success {
	background: #adebb0;
}

.screen-message.error {
	background: rgba(189, 7, 26, 0.12);
}

.screen-message-text-block {
	font-family: 'Titillium Web', sans-serif;
	color: #333;
	font-size: 1rem;
	line-height: 1.25rem;
	font-weight: 400;
}

.main-content-wrap {
	width: 74%;
	margin-bottom: 3rem;
	float: right;
}

.main-content-wrap.float-left {
	width: 75%;
	float: left;
}

.main-content-wrap.full-width {
	display: block;
	width: 90%;
	margin-right: auto;
	margin-left: auto;
	float: none;
}

.main-content-wrap.double-sidebars {
	display: inline-block;
	width: 54%;
	margin-left: 1.75rem;
	float: none;
}

.left-nav {
	width: 22%;
	padding-top: 0.5rem;
	padding-bottom: 1rem;
	padding-left: 0.5rem;
	float: left;
	border-top: 1px solid rgba(0, 0, 0, 0.01);
	border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.inside-image-header {
	width: 100%;
	height: 275px;
	background-image: url('/20170713122722/assets/images/bottom-angle-white.svg'), url('/20170713122722/assets/images/ThinkstockPhotos-507279814.jpg');
	background-position: 0px 101%, 50% 63%;
	background-size: auto, cover;
	background-repeat: no-repeat, no-repeat;
}

.breadcrumbs {
	margin-bottom: 1.5rem;
}

.breadcrumb-link {
	display: inline-block;
	margin-right: 0.45rem;
	border-bottom: 1px dotted #999;
	font-family: 'Titillium Web', sans-serif;
	color: #565656;
	font-size: 0.875rem;
	line-height: 1.25rem;
	font-weight: 400;
}

.breadcrumb-link:hover {
	border-bottom-style: none;
	color: #5d5d5d;
}

.breadcrumb-link.w--current {
	border-bottom-style: none;
	color: #333;
}

.breadcrumbs-list {
	margin-bottom: 0rem;
}

.breadcrumbs-list-item {
	display: inline-block;
}

.breadcrumb-divider {
	display: inline-block;
	margin-right: 0.45rem;
	font-family: 'Titillium Web', sans-serif;
	color: #565656;
	font-size: 0.875rem;
	font-weight: 400;
}

.parent-section-title {
	margin-bottom: 0.5rem;
	padding-bottom: 0.5rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	font-family: 'Roboto Slab', sans-serif;
	color: #006e51;
	font-size: 1.125rem;
	line-height: 1.5rem;
	text-transform: uppercase;
}

.left-nav-list {
	margin-bottom: 0px;
}

.left-nav-list-item {
	margin-bottom: 0rem;
}

.left-nav-link-level-1 {
	display: block;
	padding: 0.5rem 0.25rem 0.5rem 1rem;
	background-image: url('/20170713122722/assets/images/icon_arrow2_right_blk_25.svg');
	background-position: 5px 50%;
	background-size: 8px 8px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Titillium Web', sans-serif;
	color: #5d5d5d;
	line-height: 1.25rem;
	font-weight: 400;
	text-decoration: none;
}

.left-nav-link-level-1:hover {
	border-bottom-color: transparent;
	background-color: #f2f2f2;
	color: #006e51;
}

.nested-unordered-list {
	margin-bottom: 0px;
	margin-left: 1rem;
}

.left-nav-link-level-2 {
	display: block;
	margin-bottom: 0px;
	padding: 0.5rem 0.25rem 0.5rem 1rem;
	background-image: url('/20170713122722/assets/images/icon_arrow2_right_blk_25.svg');
	background-position: 5px 50%;
	background-size: 8px 8px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Titillium Web', sans-serif;
	color: #5d5d5d;
	font-size: 0.875rem;
	font-weight: 400;
}

.left-nav-link-level-2:hover {
	border-bottom-color: transparent;
	background-color: #f2f2f2;
	color: #006e51;
}

.left-nav-link-level-3 {
	display: block;
	padding: 0.5rem 0.25rem 0.5rem 1rem;
	background-image: url('/20170713122722/assets/images/icon_arrow2_right_blk_25.svg');
	background-position: 5px 50%;
	background-size: 8px 8px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Titillium Web', sans-serif;
	color: #5d5d5d;
	font-size: 0.825rem;
	font-weight: 400;
}

.left-nav-link-level-3:hover {
	border-bottom-color: transparent;
	background-color: #f2f2f2;
	color: #006e51;
}

.left-nav-link-level-3.w--current {
	background-color: #f2f2f2;
	font-family: 'Titillium Web', sans-serif;
	color: #006e51;
}

.right-sidebar {
	width: 18%;
	padding-top: 3rem;
	float: right;
}

.right-sidebar-section-wrap {
	margin-bottom: 1rem;
	padding-top: 1rem;
	padding-right: 0.25rem;
	border-top: 1px solid #cacaca;
}

.right-sidebar-header {
	margin-bottom: 0.5rem;
	font-family: 'Titillium Web', sans-serif;
	font-size: 1.25rem;
	line-height: 1.45rem;
	font-weight: 400;
}

.right-sidebar-paragraph {
	margin-bottom: 0.5rem;
	font-size: 0.875rem;
	line-height: 1.35rem;
}

.slide-3 {
	position: relative;
}

.slide-4 {
	position: relative;
}

.slide-5 {
	position: relative;
}

.slide-6 {
	position: relative;
}

.cta-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.form-wrap {
	display: none;
	margin-top: 1.25rem;
	margin-right: auto;
	margin-left: auto;
}

.form-wrap.v2 {
	display: block;
}

.contact-form {
	display: none;
}

.contact-form.v2 {
	display: block;
}

.form-entry-wrap {
	position: relative;
	display: block;
	width: 700px;
	margin-right: auto;
	margin-bottom: 0.5rem;
	margin-left: auto;
}

.submit-button {
	background-color: #5d5d5d;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.submit-button:hover {
	background-color: #dbdbdb;
	color: #5d5d5d;
}

.center-div {
	text-align: center;
}

.centered-wrap {
	display: block;
	width: 60%;
	margin-right: auto;
	margin-left: auto;
	text-align: center;
}

.single-link-wrap {
	display: inline-block;
	margin-right: 0.75rem;
	margin-left: 0.75rem;
}

.slideshow-wrapper {
	position: fixed;
	width: 100%;
	height: 70vh;
	margin-top: 169px;
}

.enter-question-area {
	display: inline-block;
	width: 80%;
	margin-bottom: 0px;
}

.submit-button-div {
	display: block;
	width: 700px;
	margin-right: auto;
	margin-left: auto;
	padding-left: 113px;
}

.form-reveal-section {
	display: none;
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: #f6f6f6;
}

.column-30-percent {
	width: 30%;
	float: right;
}

.column-70-percent {
	width: 68%;
	margin-right: 2%;
	float: left;
}

.button {
	display: inline-block;
	margin-top: 0.5rem;
	margin-right: 1rem;
	margin-bottom: 0.5rem;
	padding: 0.6rem 1rem 0.55rem;
	border-radius: 3px;
	background-color: #006e51;
	color: #fff;
	font-weight: 600;
	letter-spacing: 0.04rem;
	text-transform: uppercase;
}

.button:hover {
	background-color: #01503c;
}

.button.secondary {
	padding-bottom: 0.6rem;
	background-color: #333;
}

.button.secondary:hover {
	background-color: #000;
}

.button.login {
	margin-right: 0rem;
	margin-left: 1.5rem;
	padding: 0.25rem 1.25rem;
	background-color: #006e51;
	font-size: 0.9rem;
}

.button.login:hover {
	background-color: #01503c;
	color: #fff;
}

.button.products-button {
	margin-top: 1rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	border: 2px solid #fff;
	background-color: #fff;
	color: #006e51;
	font-weight: 700;
}

.button.products-button:hover {
	border-color: #669a9e;
	background-color: #01503c;
	color: #fff;
}

.button.ask-button {
	margin-top: 0rem;
	margin-bottom: 0rem;
	background-color: #fff;
	color: #333;
	line-height: 1.25rem;
	font-weight: 700;
	letter-spacing: 0.04rem;
}

.button.ask-button:hover {
	background-color: #e0e0e0;
}

.button.login {
	margin-right: 0rem;
	padding: 0.25rem 1.25rem;
	background-color: #006e51;
	font-size: 0.9rem;
}

.button.login:hover {
	background-color: #01503c;
}

.slide-learn-more-link {
	margin-top: 0.5rem;
	float: left;
	color: #fff;
	font-weight: 400;
	letter-spacing: 0.04rem;
	text-transform: uppercase;
}

.slide-learn-more-link:hover {
	opacity: 0.8;
}

.page-wrapper {
	position: relative;
	z-index: 1;
	background-color: #fff;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
}

.down-triangle {
	position: relative;
	display: block;
	width: 40px;
	height: 30px;
	margin-right: auto;
	margin-bottom: 2.75rem;
	margin-left: auto;
	border-top: 15px solid #006e51;
	border-right: 30px solid transparent;
	border-left: 30px solid transparent;
}

.spacer {
	height: 70vh;
}

.division-link-wrap {
	width: 24.7%;
	height: 280px;
	background-color: #000;
}

.home-paragraph {
	font-family: 'Roboto Slab', sans-serif;
	color: #006e51;
	font-size: 1.5rem;
	line-height: 2.25rem;
	font-weight: 400;
	text-align: center;
}

.cornbelt-text-wrap {
	position: relative;
	display: block;
	margin: 5.5rem auto;
	padding: 3.25rem 5vw 1.25rem 5vw;
	background-image: -webkit-linear-gradient(45deg, rgba(0, 110, 81, 0.83), rgba(1, 34, 26, 0.83)), url('/20170713122722/assets/images/az-subtle_1az-subtle.png');
	background-image: linear-gradient(45deg, rgba(0, 110, 81, 0.83), rgba(1, 34, 26, 0.83)), url('/20170713122722/assets/images/az-subtle_1az-subtle.png');
	background-position: 0px 0px, 0px 0px;
	background-size: auto, 50px;
}

.home-paragraph-white {
	margin-bottom: 1rem;
	font-family: 'Roboto Slab', sans-serif;
	color: #fff;
	font-size: 1.5rem;
	line-height: 2.25rem;
	font-weight: 400;
	text-align: center;
}

.sup {
	position: relative;
	bottom: 17px;
	font-size: 1rem;
	line-height: 3rem;
}

.diagonal-divider {
	position: relative;
	z-index: 0;
	width: 100%;
	height: 100px;
	margin-top: -100px;
}

.diagonal-divider.flopped {
	margin-top: 0px;
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

.diagonal-divider.products-bottom-divider {
	margin-top: 0px;
}

.diagonal-divider.hero-image-divider {
	position: absolute;
	bottom: 0px;
	margin-top: 0px;
}

.about-unordered-list {
	margin-bottom: 1rem;
	font-size: 1.15rem;
	line-height: 1.65rem;
}

.spacer-outer-wrap {
	padding-top: 169px;
}

.social-text {
	margin-left: 0.5rem;
	font-size: 1rem;
	line-height: 1.4rem;
	font-weight: 600;
	text-align: left;
}

.two-part-mobile-nav-wrapper {
	display: none;
}

.slide-image-wrapper {
	width: 100%;
	height: 100%;
	background-position: 0px 0px, 0px 0px;
	background-size: auto, 50px;
}

.slide-image-wrapper.img1 {
	background-position: 0px 0px, 0px 0px, 50% 50%;
	background-size: auto, 50px, cover;
	background-repeat: repeat, repeat, no-repeat;
}

.slide-caption-inner-wrap {
	max-width: 800px;
}

.main-nav-link-2 {
	padding: 1rem;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	font-size: 1.05rem;
	line-height: 1.05rem;
	font-weight: 400;
}

.main-nav-link-2:hover {
	box-shadow: inset 0 -4px 0 0 #fff;
}

.nav-container {
	position: relative;
	display: block;
	max-width: 1232px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.nav-container.header-container {
	max-width: 1232px;
}

.nav-drop-toggle-link-2 {
	display: inline-block;
	width: 100%;
	padding: 1rem;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	font-size: 1.05rem;
	line-height: 1.05rem;
	text-align: center;
}

.navigation-menu {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: calc(850px - 3rem);
	margin-right: auto;
	margin-left: auto;
	float: right;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	text-align: left;
}

.nav-drop-list-link-2 {
	padding: 0.5rem 1rem;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Verdana, Geneva, sans-serif;
	color: #fff;
	font-size: 0.9rem;
	font-weight: 400;
}

.nav-drop-list-link-2:hover {
	background-color: #046149;
	background-image: none;
	color: #fff;
}

.header-links {
	position: relative;
	top: -5px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 40%;
	float: right;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.nav-drop-wrap {
	position: relative;
}

.nav-drop-toggle-2 {
	position: relative;
	width: 100%;
	padding: 0px;
	border-right: 1px none transparent;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: hsla(0, 0%, 65%, 0.5);
}

.nav-drop-toggle-2:hover {
	box-shadow: inset 0 -4px 0 0 #fff;
	color: #fff;
}

.search-dd-button {
	width: 3rem;
	height: 3rem;
	float: right;
	background-image: url('/20170713122722/assets/images/search_wht.svg');
	background-position: 50% 50%;
	background-size: 18px 18px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.search-dd-button:hover {
	box-shadow: inset 0 -4px 0 0 #fff;
}

.nav-bar {
	display: block;
	margin-top: 1rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	background-color: transparent;
	background-image: -webkit-linear-gradient(0deg, #006e51, #02271e);
	background-image: linear-gradient(90deg, #006e51, #02271e);
}

.header-section {
	position: fixed;
	width: 100%;
	border-top: 0px none transparent;
	background: #fff;
	box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.6);
	z-index: 6;
	padding-top: 1.3rem;
	top: 0px;
}


/*RESOLVES SCROLLING JITTER CAUSED BY FIXED BACKGROUND IMAGE FOR IE 10/11 */
@media screen and (-ms-high-contrast: active),
	(-ms-high-contrast: none) {
	.header-section {
		position: fixed;
		width: 100%;
		border-top: 0px none transparent;
		z-index: auto;
		background: none;
		box-shadow: none;
		padding: 0px;
	}

	.header-section-inner {
		padding-top: 1.3rem;
		z-index: 1000;
		background: #fff;
		box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.6);
		position: relative;
	}
}

.top-nav-link-2 {
	display: inline-block;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	margin-left: 2.5rem;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-left: 1.5rem;
	float: right;
	background-image: url('/20170713122722/assets/images/locations-pin-blk.svg');
	background-position: 0px 50%;
	background-size: 20px 20px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #006e51;
	font-size: 1rem;
	line-height: 1.25rem;
	font-weight: 700;
	text-transform: uppercase;
}

.top-nav-link-2:hover {
	background-image: url('/20170713122722/assets/images/locations-pin-blk_1.svg');
	background-size: 20px 20px;
	color: #000;
}

.logo-home-link {
	display: inline-block;
	width: 320px;
}

.search-form-wrapper {
	width: 400px;
	padding-top: 1rem;
	padding-right: 1rem;
	padding-left: 1rem;
	float: right;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	background-color: #a8a8a8;
}

.slide-learn-more-link-inline {
	margin-top: 0px;
	font-family: 'Titillium Web', sans-serif;
	color: #fff;
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: 0.04rem;
	text-transform: uppercase;
}

.slide-learn-more-link-inline:hover {
	opacity: 0.8;
}

.header-image-wrap {
	position: relative;
	width: 100%;
	height: 500px;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.header-image-wrap.inverse-angle {
	position: relative;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.header-image-wrap.inverse-angle.products-image {
	height: 600px;
	background-position: 50% 25%;
}

.extra-top-margin {
	margin-top: 1rem;
}

.bottom-margin-for-links {
	margin-bottom: 1rem;
}

.bottom-margin-for-links.smaller-margin {
	display: inline-block;
	width: 50%;
	margin-bottom: 0.5rem;
}

.bottom-margin-for-links.smaller-margin.align-right {
	text-align: right;
}

.products-section-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-align-content: stretch;
	-ms-flex-line-pack: stretch;
	align-content: stretch;
}

.product-wrap {
	width: 30%;
	margin-right: 5%;
	margin-bottom: 1rem;
	margin-left: 5%;
}

.product-wrap.no-margin {
	margin-right: 0%;
	margin-left: 0%;
}

.product-image-padding {
	padding-bottom: 1rem;
}

.horizontal-divider {
	margin-top: 2rem;
	margin-bottom: 1rem;
	border-bottom: 1px solid #5d5d5d;
}

.product-link-block {
	position: relative;
}

.links-row-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.featured-video-wrapper-copy {
	width: 50%;
	float: left;
}

.product-description {
	position: absolute;
	right: 5px;
	bottom: 5px;
	color: #fff;
	font-weight: 600;
	text-align: right;
	text-transform: capitalize;
}

.video-placeholder {
	height: 300px;
	background-image: url('/20170713122722/assets/images/video-placeholder.png');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

html.w-mod-js *[data-ix="fade-in-bottom-page-loads"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="fade-in-left-scroll-in"] {
	opacity: 0;
	-webkit-transform: translate(-50px, 0px);
	-ms-transform: translate(-50px, 0px);
	transform: translate(-50px, 0px);
}

html.w-mod-js *[data-ix="fade-in-right-scroll-in"] {
	opacity: 0;
	-webkit-transform: translate(50px, 0px);
	-ms-transform: translate(50px, 0px);
	transform: translate(50px, 0px);
}

html.w-mod-js *[data-ix="fade-in-top-scroll-in"] {
	opacity: 0;
	-webkit-transform: translate(0px, -50px);
	-ms-transform: translate(0px, -50px);
	transform: translate(0px, -50px);
}

html.w-mod-js *[data-ix="fade-in-bottom-scroll-in"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="bounce-in-scroll-in"] {
	opacity: 0;
	-webkit-transform: scale(0.6000000000000006, 0.6000000000000006);
	-ms-transform: scale(0.6000000000000006, 0.6000000000000006);
	transform: scale(0.6000000000000006, 0.6000000000000006);
}

html.w-mod-js *[data-ix="header-scroll-srink"] {
	width: 320px;
}

html.w-mod-js *[data-ix="show-on-scroll"] {
	display: none;
}

@media (max-width: 1200px) {
	.header-search-container {
		width: 100%;
	}
}

@media (max-width: 991px) {
	h1 {
		font-size: 2.25rem;
		line-height: 2.75rem;
	}

	h2 {
		font-size: 1.75rem;
		line-height: 2.25rem;
	}

	h3 {
		font-size: 1.5rem;
		line-height: 2rem;
	}

	h4 {
		font-size: 1.25rem;
		line-height: 1.75rem;
	}

	h5 {
		font-size: 1.1rem;
		line-height: 1.6rem;
	}

	h6 {
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.main-nav-link {
		display: block;
		padding-left: 1rem;
		border-bottom: 0px solid #000;
		color: #fff;
	}

	.main-nav-link:hover {
		background-color: #01503c;
		box-shadow: none;
	}

	.main-nav-link.w--current {
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
		padding-left: 1.5rem;
		border-bottom-width: 1px;
		border-bottom-color: rgba(93, 63, 58, 0.35);
		background-color: #014736;
		box-shadow: inset 6px 0 0 0 #006e51;
		color: #fff;
	}

	.main-nav-link.mobile-duplicate {
		width: 88%;
		margin-right: 0px;
		margin-left: 2px;
		padding-top: 1rem;
		padding-bottom: 1rem;
		padding-left: 1.5rem;
		border-bottom-style: none;
	}

	.main-nav-link.mobile-duplicate:hover {
		background-color: #01503c;
		color: #fff;
	}

	.section {
		padding: 35px 10px;
	}

	.section.accent {
		padding: 1rem 0px 1.5rem;
	}

	.section.divisions-area {
		padding-bottom: 7rem;
	}

	.section.bottom-content-area {
		padding-right: 0px;
		padding-left: 0px;
	}

	.section.products-section {
		padding-right: 0px;
		padding-left: 0px;
	}

	.cta-section.centered-accented {
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}

	.slider-arrow-link {
		font-size: 1.5rem;
	}

	.slider-arrow-link.right {
		width: 50px;
		height: 50px;
		margin-right: 0px;
	}

	.slider-arrow-link.left {
		width: 50px;
		height: 50px;
		margin-left: 0px;
	}

	.section-heading.centered {
		margin-bottom: 1.5rem;
	}

	.section-heading.centered-white {
		margin-bottom: 1.5rem;
	}

	.footer.accent {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.container.lower-home-container {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.container.signoff-container,
	.footer-container.signoff-container {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.signoff {
		padding-bottom: 1rem;
	}

	.unordered-list {
		padding-left: 20px;
	}

	.column-25-percent {
		width: 30%;
	}

	.column-25-percent.no-padding {
		width: 100%;
		margin-top: 0px;
	}

	.column-25-percent.no-padding.extra-margin {
		width: 100%;
		margin-top: 0px;
		margin-right: 4%;
	}

	.email-link {
		margin-bottom: 1rem;
	}

	.grip-footer-logo {
		position: static;
		display: block;
		margin-right: auto;
		margin-left: auto;
		float: none;
	}

	.copyright-text {
		margin-bottom: 0.5rem;
		text-align: center;
	}

	.header-search-container {
		width: 100%;
	}

	.divisions-column {
		width: 100%;
	}

	.flex-row.divisions {
		margin-top: 0rem;
		margin-bottom: 1.5rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		text-align: center;
	}

	.slide-nav {
		width: 500px;
	}

	.slide-1 {
		background-position: 50% 100%;
		background-size: cover;
	}

	.hamburger-icon {
		font-size: 2rem;
		line-height: 2rem;
	}

	.signoff-links-div {
		display: block;
		margin-bottom: 1rem;
		text-align: center;
	}

	.embed-video {
		width: 690px;
	}

	.slider-caption-div {
		width: 45%;
		padding-right: 5vw;
		padding-bottom: 1.5rem;
		padding-left: 2rem;
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
	}

	.slider-heading {
		font-size: 1.5rem;
		line-height: 2rem;
	}

	.mobile-locations-link {
		display: inline-block;
		padding-left: 25px;
		background-image: url('/20170713122722/assets/images/locations-pin-white.svg');
		background-position: 0px 50%;
		background-size: contain;
		background-repeat: no-repeat;
		-webkit-transition: all 200ms ease;
		transition: all 200ms ease;
		color: #fff;
		font-size: 0.95rem;
		font-weight: 600;
		text-align: left;
	}

	.mobile-locations-link:hover {
		background-image: url('/20170713122722/assets/images/locations-pin-white.svg');
		background-position: 0px 50%;
		background-size: contain;
		background-repeat: no-repeat;
	}

	.mobile-locations-div {
		display: block;
		width: 110px;
		margin-top: 1rem;
		margin-right: auto;
		margin-left: auto;
	}

	.styles-wrapper {
		padding-right: 4rem;
		padding-left: 4rem;
	}

	.header-link {
		font-size: 2.25rem;
	}

	.ordered-list {
		padding-left: 20px;
	}

	.main-content-wrap {
		display: block;
		width: 90%;
		margin-right: auto;
		margin-left: auto;
		float: none;
	}

	.main-content-wrap.float-left {
		width: 90%;
		float: none;
	}

	.main-content-wrap.double-sidebars {
		display: block;
		width: 90%;
		margin-right: auto;
		margin-left: auto;
	}

	.right-sidebar {
		display: block;
		width: 90%;
		margin-right: auto;
		margin-bottom: 2rem;
		margin-left: auto;
		padding-top: 0rem;
		float: none;
	}

	.right-sidebar-section-wrap {
		padding-right: 0rem;
	}

	.right-sidebar-paragraph {
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.centered-wrap {
		width: 90%;
	}

	.slideshow-wrapper {
		height: 40vh;
		margin-top: 160px;
	}

	.column-30-percent {
		width: 100%;
	}

	.column-70-percent {
		width: 100%;
	}

	.down-triangle {
		margin-bottom: 2rem;
	}

	.spacer {
		height: 40vh;
	}

	.division-link-wrap {
		width: 49.7%;
		margin-bottom: 0.5%;
	}

	.spacer-outer-wrap {
		padding-top: 160px;
	}

	.footer-inner-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.footer-inner-wrap.lower-inner {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.two-part-mobile-nav-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom: 1px solid rgba(93, 63, 58, 0.35);
	}

	.mobile-menu-arrow {
		width: 15%;
		border-left: 1px solid rgba(93, 63, 58, 0.35);
		background-image: url('/20170713122722/assets/images/white-arrow-right.svg');
		background-position: 50% 50%;
		background-size: 20px 20px;
		background-repeat: no-repeat;
	}

	.mobile-menu-arrow:hover {
		background-color: #014736;
	}

	.main-nav-link-2 {
		padding-top: 1rem;
		padding-bottom: 1rem;
		padding-left: 1.5rem;
		border-bottom: 1px solid rgba(93, 63, 58, 0.35);
		color: #fff;
		font-weight: 600;
	}

	.main-nav-link-2:hover {
		background-color: #01503c;
		box-shadow: none;
	}

	.main-nav-link-2.w--current {
		background-color: #01503c;
		box-shadow: inset 6px 0 0 0 rgba(0, 0, 0, 0.25);
		color: #fff;
	}

	.nav-container {
		width: 100vw;
	}

	.nav-container.header-container {
		width: 96vw;
	}

	.mobile-menu-button {
		display: inline-block;
		width: 64px;
		height: 50px;
		padding: 10px 12px 10px 16px;
		float: left;
		-webkit-transition: all 200ms ease;
		transition: all 200ms ease;
		color: #fff;
	}

	.mobile-menu-button:hover {
		background-color: #01503c;
	}

	.mobile-menu-button.w--open {
		background: #01503c;
	}

	.nav-drop-toggle-link-2 {
		text-align: left;
	}

	.navigation-menu {
		position: absolute;
		display: block;
		width: 80%;
		max-width: none;
		float: left;
		border-top: 1px none #fff;
		background-color: #006e51;
	}

	.header-links {
		top: -5px;
	}

	.search-mobile-div {
		position: absolute;
		top: 0px;
		right: 0px;
		display: inline-block;
		margin-top: 0rem;
		float: right;
	}

	.nav-drop-toggle-2 {
		border-bottom: 1px solid #dbdbdb;
	}

	.nav-drop-toggle-2:hover {
		background-color: #01503c;
		box-shadow: none;
	}

	.search-dd-button {
		display: inline-block;
		width: 64px;
		height: 50px;
		float: right;
	}

	.search-dd-button:hover {
		background-color: #01503c;
		box-shadow: none;
	}

	.nav-bar {
		position: relative;
		display: block;
		width: 100%;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-align-content: center;
		-ms-flex-line-pack: center;
		align-content: center;
	}

	.header-section {
		width: 100%;
	}

	.logo-home-link {
		width: 280px;
	}

	.header-image-wrap {
		background-size: 105%, cover;
	}

	.bottom-margin-for-links.smaller-margin {
		width: 100%;
	}

	.bottom-margin-for-links.smaller-margin.align-right {
		text-align: left;
	}

	.links-row-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: column-reverse;
		-ms-flex-direction: column-reverse;
		flex-direction: column-reverse;
	}

	.featured-video-wrapper-copy {
		width: 75%;
	}
}

@media (max-width: 767px) {
	h1 {
		font-size: 2rem;
		line-height: 2.5rem;
	}

	h2 {
		font-size: 1.5rem;
		line-height: 2rem;
	}

	h3 {
		font-size: 1.25rem;
		line-height: 1.75rem;
	}

	h4 {
		font-size: 1.1rem;
		line-height: 1.6rem;
	}

	h5 {
		font-size: 1rem;
		line-height: 1.5rem;
	}

	h6 {
		font-size: 0.9rem;
		line-height: 1.4rem;
	}

	blockquote {
		font-size: 1.1rem;
		line-height: 1.8rem;
	}

	.primary-button {
		display: block;
		margin-right: auto;
		margin-left: auto;
		font-size: 0.875rem;
	}

	.primary-button.white {
		display: inline-block;
		font-size: 0.95rem;
	}

	.primary-button.white.submit-button {
		display: block;
		margin-right: auto;
		margin-left: auto;
		float: none;
	}

	.section {
		padding-right: 20px;
		padding-left: 20px;
	}

	.section.accent {
		padding-right: 0px;
		padding-bottom: 1rem;
		padding-left: 0px;
	}

	.section.divisions-area {
		padding-right: 0px;
		padding-bottom: 6rem;
		padding-left: 0px;
	}

	.cta-section {
		padding-right: 20px;
		padding-left: 20px;
		text-align: center;
	}

	.cta-section.centered-accented {
		padding: 25px 0px;
	}

	.cta-heading {
		margin-right: 0rem;
		margin-bottom: 0.75rem;
		font-size: 1.75rem;
		line-height: 2.25rem;
	}

	.section-heading.centered {
		margin-bottom: 1rem;
		font-size: 2rem;
		line-height: 2.5rem;
	}

	.section-heading.centered-white {
		margin-bottom: 1rem;
		font-size: 2rem;
		line-height: 2.5rem;
	}

	.footer {
		padding-right: 20px;
		padding-left: 20px;
	}

	.footer.accent {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-left: 0px;
		padding-right: 0px;
		padding-bottom: 20px;
		padding-left: 0px;
	}

	.container.flex-horizontal,
	.footer-container.flex-horizontal {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.footer-container.flex-horizontal {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.container.center-flex {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.footer-heading {
		text-align: left;
	}

	.half-div {
		width: 100%;
		margin-top: 1rem;
		margin-right: 0rem;
		margin-left: 0rem;
	}

	.column-25-percent {
		width: 100%;
		margin-top: 1rem;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.column-25-percent.no-padding {
		width: 100%;
	}

	.column-25-percent.no-padding.extra-margin {
		width: 100%;
	}

	.column-50-percent {
		padding-right: 0.75rem;
	}

	.column-50-percent.middle-border {
		padding-left: 0.75rem;
		padding-right: 0;
	}

	.grip-footer-logo.mobile-grip-link {
		position: static;
	}

	.copyright-text {
		display: inline-block;
		text-align: center;
	}

	.header-top-row {
		margin-bottom: 0rem;
	}

	.divisions-column.image-3 {
		width: 100%;
	}

	.flex-row {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.flex-row.divisions {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.hero-slider {
		background-color: #006e51;
		background-image: -webkit-linear-gradient(0deg, rgba(0, 110, 81, 0.83), rgba(1, 34, 26, 0.83)), url('/20170713122722/assets/images/az-subtle_1az-subtle.png');
		background-image: linear-gradient(90deg, rgba(0, 110, 81, 0.83), rgba(1, 34, 26, 0.83)), url('/20170713122722/assets/images/az-subtle_1az-subtle.png');
		background-position: 0px 0px, 0px 0px;
		background-size: 100%, 50px;
	}

	.slide-nav {
		width: 370px;
	}

	.arrow-left {
		font-size: 1.25rem;
	}

	.arrow-right {
		font-size: 1.25rem;
	}

	.slide-1 {
		background-image: -webkit-linear-gradient(270deg, transparent, transparent);
		background-image: linear-gradient(180deg, transparent, transparent);
	}

	.signoff-link {
		text-align: center;
	}

	.divider {
		margin-top: 1rem;
		border-bottom: 1px solid grey;
	}

	.signoff-links-div {
		margin-bottom: 1.25rem;
	}

	.embed-video {
		width: 100%;
		margin-top: 1.5rem;
	}

	.slider-caption-div {
		position: static;
		width: 100%;
		padding-right: 3rem;
		padding-bottom: 3rem;
		padding-left: 3rem;
		background-color: transparent;
		background-image: none;
	}

	.slider-heading {
		font-size: 1.25rem;
		line-height: 1.75rem;
	}

	.divisions-image-wrap.photo-1 {
		height: 250px;
	}

	.intro-paragraph {
		font-size: 1.2rem;
		line-height: 1.8rem;
	}

	.field-label {
		display: inline-block;
		width: 75%;
		margin-right: 0rem;
		text-align: left;
	}

	input.text-field {
		width: 75% !important;
	}

	.cta-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.form-entry-wrap {
		width: 100%;
		text-align: center;
	}

	.centered-wrap {
		width: 100%;
	}

	.mobile-footer-div {
		margin-right: 0.5rem;
	}

	.slideshow-wrapper {
		position: static;
		height: auto;
		margin-top: 0px;
		padding-top: 171px;
	}

	.enter-question-area {
		width: 75%;
	}

	.submit-button-div {
		width: 100%;
		padding-left: 0px;
	}

	.form-reveal-section {
		display: none;
	}

	.button.ask-button {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.slide-learn-more-link {
		font-size: 0.9rem;
	}

	.home-paragraph {
		font-size: 1.4rem;
		line-height: 1.9rem;
	}

	.cornbelt-text-wrap {
		margin-top: 4rem;
		margin-bottom: 4rem;
	}

	.home-paragraph-white {
		font-size: 1.4rem;
		line-height: 2.1rem;
		font-weight: 400;
	}

	.sup {
		top: -11px;
	}

	.about-unordered-list {
		padding-left: 30px;
	}

	.spacer-outer-wrap {
		display: none;
	}

	.slide-image-wrapper {
		height: 300px;
	}

	.header-links {
		top: -5px;
		width: 30%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: column-reverse;
		-ms-flex-direction: column-reverse;
		flex-direction: column-reverse;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.nav-bar {
		margin-top: 0.5rem;
	}

	.top-nav-link-2 {
		margin-top: 0.5rem;
	}

	.slide-learn-more-link-inline {
		font-size: 0.9rem;
	}

	.header-image-wrap {
		height: 400px;
	}

	.header-image-wrap.inverse-angle.products-image {
		height: 500px;
	}

	.products-section-wrap {
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.product-wrap {
		width: 47%;
		margin-right: 0%;
		margin-left: 3%;
	}

	.product-wrap.no-margin {
		margin-right: 3%;
	}

	.product-wrap.no-margin.mobile-right {
		margin-right: 0%;
		margin-left: 3%;
	}

	.product-wrap.mobile-left {
		margin-right: 3%;
		margin-left: 0%;
	}

	.video-placeholder {
		height: 250px;
	}
}

@media (max-width: 479px) {
	h1 {
		font-size: 1.9rem;
		line-height: 2.4rem;
	}

	.primary-button {
		margin-bottom: 8px;
	}

	.section.bottom-content-area {
		padding-top: 1rem;
	}

	.cta-heading {
		font-size: 1.5rem;
		line-height: 2rem;
	}

	.slider-arrow-link.right {
		position: absolute;
		top: 320px;
		right: 0px;
		bottom: 0px;
		display: block;
	}

	.slider-arrow-link.left {
		top: 320px;
		padding-left: 0px;
	}

	.section-heading.centered {
		display: block;
		width: 80%;
		margin-right: auto;
		margin-left: auto;
		font-size: 1.75rem;
		line-height: 2.25rem;
	}

	.section-heading.centered-white {
		display: block;
		width: 80%;
		margin-right: auto;
		margin-left: auto;
		font-size: 1.75rem;
		line-height: 2.25rem;
	}

	.footer.accent {
		padding-top: 30px;
	}

	.container,
	.footer-container {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.container.flex-horizontal,
	.footer-container.flex-horizontal {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.container.divisions-container {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.container.history-container {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.grip-subtext {
		display: inline;
	}

	.footer-heading {
		margin-bottom: 5px;
		text-align: left;
	}

	.search-button {
		font-size: 0.75rem;
	}

	.half-div {
		margin-top: 1rem;
	}

	.column-25-percent {
		padding-left: 1rem;
	}

	.column-25-percent.no-padding {
		width: 100%;
		margin-right: 0%;
		padding-left: 0rem;
	}

	.column-25-percent.no-padding.extra-margin {
		margin-right: 0%;
		padding-left: 0rem;
	}

	.grip-footer-logo {
		margin-top: 0.5rem;
	}

	.copyright-text {
		display: block;
		margin-right: 0px;
		margin-left: 0rem;
	}

	.header-top-row {
		position: relative;
	}

	.divisions-column {
		width: 100%;
		margin-right: 0rem;
		margin-bottom: 0.5rem;
		margin-left: 0rem;
	}

	.divisions-title {
		font-size: 1.3rem;
		line-height: 2rem;
	}

	.flex-row.divisions {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.hero-slider {
		position: relative;
		height: 380px;
	}

	.slide-nav {
		display: none;
	}

	.arrow-left {
		font-size: 1.15rem;
	}

	.arrow-right {
		font-size: 1.15rem;
	}

	.slide-1 {
		background-position: 50% 100%;
	}

	.slide-2 {
		background-image: none;
		background-position: 0px 0px;
		background-size: auto;
		background-repeat: repeat;
	}

	.signoff-link {
		display: inline-block;
		margin-bottom: 0.5rem;
	}

	.signoff-links-div {
		display: block;
		margin-right: auto;
		margin-bottom: 1rem;
		margin-left: auto;
	}

	.embed-video {
		width: 100%;
	}

	.column-50-percent {
		width: 100%;
		padding-right: 0;
	}

	.column-50-percent.middle-border {
		width: 100%;
		margin-top: 1.125rem;
		padding-top: 0.5rem;
		padding-left: 0;
		border-top: 1px solid hsla(0, 0%, 66%, 0.5);
		border-left-style: none;
	}

	.slider-caption-div {
		padding: 1.25rem 2rem 3.5rem;
	}

	.slider-heading {
		font-size: 1.1rem;
		line-height: 1.6rem;
	}

	.divisions-image-wrap.photo-1 {
		height: 180px;
	}

	.styles-wrapper {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.intro-paragraph {
		font-size: 1.15rem;
		line-height: 1.75rem;
	}

	.field-label {
		width: 90%;
	}

	input.text-field {
		width: 90% !important;
	}

	.single-link-wrap {
		width: 100%;
		margin-right: 0rem;
		margin-left: 0rem;
	}

	.slideshow-wrapper {
		padding-top: 158px;
	}

	.enter-question-area {
		width: 90%;
	}

	.form-reveal-section {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}

	.division-link-wrap {
		width: 100%;
		height: 220px;
		margin-bottom: 0.5rem;
	}

	.home-paragraph {
		font-size: 1.3rem;
		line-height: 1.9rem;
	}

	.home-paragraph-white {
		font-size: 1.2rem;
		line-height: 1.9rem;
	}

	.sup {
		top: -9px;
	}

	.slide-image-wrapper {
		height: 200px;
	}

	.nav-bar {
		margin-top: 0rem;
	}

	.header-section {
		padding-top: 1rem;
	}

	.top-nav-link-2 {
		margin-left: 0rem;
		padding-left: 22px;
		float: none;
		background-size: auto 18px;
		font-size: 0.9rem;
	}

	.top-nav-link-2:hover {
		background-size: auto 18px;
	}

	.logo-home-link {
		display: inline-block;
		width: 60%;
		min-width: 100px;
		margin-top: 2px;
	}

	.search-form-wrapper {
		width: 100%;
	}

	.header-image-wrap {
		background-image: url('/20170713122722/assets/images/bottom-angle-white.svg'), url('/20170713122722/assets/images/ThinkstockPhotos-599971330.jpg');
		background-position: 50% 101%, 50% 50%;
		background-size: 150%, cover;
		background-repeat: no-repeat, no-repeat;
	}

	.product-wrap {
		width: 80%;
		margin-right: 10%;
		margin-left: 10%;
	}

	.product-wrap.no-margin.mobile-left {
		width: 100%;
		margin-right: 0%;
	}

	.product-wrap.no-margin.mobile-right {
		margin-left: 0%;
	}

	.product-wrap.mobile-right {
		width: 100%;
		margin-right: 0%;
		margin-left: 0%;
	}

	.product-wrap.mobile-left {
		width: 100%;
		margin-right: 0%;
	}

	.featured-video-wrapper-copy {
		width: 100%;
	}

	.video-placeholder {
		height: 200px;
	}
}


/****************** Tablesaw ***********************/
/****************************************************/
table.styled.tablesaw th,
table.styled.tablesaw td {
	border-left: 0;
	border-top: 0;
}


@media (max-width: 50em) {
	table.styled.tablesaw th,
	table.styled.tablesaw td {
		border-right: 0;
	}
}

@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: none;
	}
}

/***************** Webflow Styles *******************/
/****************************************************/
body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

/* CONTROL CURSOR CLICK & DRAG HIGHLIGHT COLOR */
/* Mozilla-based browsers */

/*RESOLVES SCROLLING JITTER CAUSED BY FIXED BACKGROUND IMAGE FOR IE 10/11 */
@media screen and (-ms-high-contrast: active),
	(-ms-high-contrast: none) {
	html {
		overflow: hidden;
		height: 100%;
	}

	body {
		overflow: auto;
		height: 100%;
		overflow-y: scroll;
	}
}

@media screen and (-ms-high-contrast: active),
	(-ms-high-contrast: none) and (min-width: 767px) {
	.slideshow-wrapper {
		width: calc(100% - 17px);
	}
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 991px ) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and ( min-width: 767px ) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}


/****************** Print ***********************/
/*************************************************/
#printHeader,
#printFooter {
	display: none;
}

/****************** Header ***********************/
/*************************************************/
.header-section a {
	border-bottom: none;
}

/************** Accesible Menu *******************/
/*************************************************/
ul.nav-menu-wrap {
	margin: 0px;
	padding: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: calc(850px - 3rem);
	margin-right: auto;
	margin-left: auto;
	float: right;
}

ul.nav-menu-wrap li {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}

.nav-menu-wrap .nav-drop-toggle {
	position: relative;
	width: auto;
	padding: 0px;
	border-right: 1px none transparent;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: hsla(0, 0%, 65%, 0.5);
	margin-right: auto;
	margin-left: auto;
}

.nav-menu-wrap .nav-drop-toggle .nav-link {
	display: inline-block;
	width: 100%;
	padding: 1rem;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	font-size: 1.05rem;
	line-height: 1.05rem;
	text-align: center;
}

ul.nav-menu-wrap .nav-drop-toggle .nav-link:hover,
ul.nav-menu-wrap .nav-drop-toggle .nav-link.active-item {
	box-shadow: inset 0 -4px 0 0 #fff;
}

.nav-menu-wrap .nav-drop-toggle.has-dropdown .container-wrapper {
	position: absolute;
	left: 0;
	top: 100%;
	bottom: auto;
	max-height: 0px;
	overflow: hidden;
	margin: -4px 0px 0px 0px;
}

.nav-menu-wrap .nav-drop-toggle.has-dropdown.open .container-wrapper {
	max-height: 500px;
	transition: max-height 400ms ease;
	-webkit-transition: max-height 400ms ease;
	opacity: 1;
	height: auto;
}

.nav-menu-wrap .nav-drop-toggle.has-dropdown .container-wrapper .nav-drop-list {
	height: auto !important;
	position: static;
	padding: 0.25rem 0rem 10px;
	border-top-style: none;
	border-top-width: 4px;
	background-color: rgba(0, 110, 81, 0.9);
	border-top: 4px solid #fff;
	margin: 0px;
	display: inline-block;
}

.nav-menu-wrap .nav-drop-toggle.has-dropdown .container-wrapper .nav-drop-list li a {
	padding: 0.5rem 1rem;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Verdana, Geneva, sans-serif;
	color: #fff;
	font-size: 0.9rem;
	font-weight: 400;
	display: block;
	min-width: 150px;
}

.nav-menu-wrap .nav-drop-toggle.has-dropdown.open .container-wrapper .nav-drop-list li a:hover,
.nav-menu-wrap .nav-drop-toggle.has-dropdown .container-wrapper .nav-drop-list li a.w--current {
	background-color: #046149;
	background-image: none;
	color: #fff;
}

/****************** Mobile navigation ****************/
/*****************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

.mobile-navigation-menu {
	background: #006e51;
	color: #fff;
}

.mobile-navigation-menu a {
	border-bottom: none;
}

.mobile-navigation-menu.mm-menu li {
	border-bottom: 1px solid rgba(93, 63, 58, 0.35);
}

.mobile-navigation-menu.mm-menu li:after {
	border-bottom: 0px;
}

.mobile-navigation-menu.mm-menu li a {
	padding-top: 1rem;
	padding-bottom: 1rem;
	padding-left: 1.5rem;
	color: #fff;
	font-weight: 600;
	font-size: 1.05rem;
	line-height: 1.05rem;
}

.mobile-navigation-menu.mm-menu li a:hover {
	background-color: #01503c;
	color: #fff;
}

.mobile-navigation-menu.mm-menu li.active a.nav-link {
	background-color: #01503c;
	box-shadow: inset 6px 0 0 0 rgba(0, 0, 0, 0.25);
	color: #fff;
}

.mobile-navigation-menu.mm-menu li .mm-btn_next:after {
	border-color: #fff;
}

.mobile-navigation-menu .mm-navbar {
	height: 50px;
	text-align: left;
}

.mobile-navigation-menu .mm-panels > .mm-panel > .mm-navbar + .mm-listview {
	margin-top: -10px;
}

.mobile-navigation-menu .mm-navbar .mm-btn_prev {
	top: 6px;
	left: -8px;
}

.mobile-navigation-menu .mm-navbar .mm-btn_prev:before {
	border-color: #fff;
}

.mobile-navigation-menu .mm-navbar .mm-navbar__title {
	margin-top: 8px;
	color: #fff;
	font-weight: 600;
	font-size: 1.05rem;
	line-height: 1.05rem;
}

@media screen and (-ms-high-contrast: active),
	(-ms-high-contrast: none) {
	.mm-wrapper_opened .slideshow-wrapper {
		position: static;
	}

	.mm-wrapper_opened .spacer-outer-wrap {
		display: none;
	}

	.mobile-navigation-menu.mm-menu_opened {
		z-index: 1000;
	}
}

/***************** Homepage  **************************/
/******************************************************/
.home-paragraph p {
	font-weight: inherit;
	line-height: inherit;
	color: inherit;
	font-family: inherit;
	font-size: inherit;
}

.home-paragraph li,
.home-paragraph a {
	font-weight: inherit;
	line-height: inherit;
	font-family: inherit;
	font-size: inherit;
}

.half-div p {
	font-size: 1.15rem;
	line-height: 1.65rem;
	text-align: left;
}

.half-div ul {
	margin-bottom: 1rem;
	font-size: 1.15rem;
	line-height: 1.65rem;
}

.section-heading.centered-white a,
.section-heading.centered-white a:hover {
	color: inherit;
	border-bottom: none;
	text-decoration: none;
	font-size: inherit;
	line-height: inherit;
}

.home-paragraph-white p,
.home-paragraph-white li {
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	font-weight: inherit;
	font-family: inherit;
}

.home-paragraph-white p {
	margin-bottom: 2rem;
}

.embed-video p {
	margin: 0px;
}

.embed-video video {
	width: 100%;
	height: auto;
}

@media (max-width: 767px) {
	.half-div ul {
		padding-left: 30px;
	}
}

/***************** Slideshow  **************************/
/******************************************************/
.slider-heading {
	display: block;
}

.slider-heading p {
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	font-weight: inherit;
	margin-bottom: 0px;
	font-family: 'Roboto Slab', sans-serif;
}

.slide-learn-more-link {
	float: none;
	border-bottom: 0px;
}

.slide-learn-more-link:hover {
	opacity: 0.8;
	color: #fff;
	font-weight: 400;
}

/****************** Inside Page ******************/
/*************************************************/
.left-nav-list a {
	border-bottom: none;
}

.inside-body-section {
	min-height: 500px;
}

.left-nav-list li {
	margin-bottom: 0rem;
	padding-bottom: 0px;
}

.left-nav-list li li a {
	margin-bottom: 0px;
	padding: 0.5rem 0.25rem 0.5rem 1rem;
	line-height: 1.25rem;
	font-size: 0.875rem;
}

.left-nav-list li li li a {
	font-size: 0.825rem;
}

.left-nav-list li a.active {
	background-color: #f2f2f2;
	font-family: 'Titillium Web', sans-serif;
	color: #006e51;
}

img {
	height: auto;
	max-width: 100%;
	vertical-align: middle;
	display: inline-block;
}

@media (max-width: 991px) {
	.body-background-image {
		background-image: none;
	}
}

@media (max-width: 767px) {
	.mobile-friendly-image,
	.mobile_friendly_image {
		display: block;
		float: none !important;
	}
}

/*************** Right Sidebar ********************/
/*************************************************/
.right-sidebar .box {
	margin-bottom: 1rem;
	padding-top: 1rem;
	padding-right: 0.25rem;
	border-top: 1px solid #cacaca;
	font-size: 0.875rem;
	line-height: 1.35rem;
}

.right-sidebar .box h4,
.right-sidebar .box h3 {
	margin-bottom: 0.5rem;
	margin-top: 0px;
	padding: 0px;
	font-family: 'Titillium Web', sans-serif;
	font-size: 1.25rem;
	line-height: 1.45rem;
	font-weight: 400;
	color: #333;
}

.right-sidebar .box p {
	margin-bottom: 0.5rem;
}

.right-sidebar .box p,
.right-sidebar .box li,
.right-sidebar .box a {
	font-size: 0.875rem;
	line-height: 1.35rem;
}

.right-sidebar .box td {
	vertical-align: top;
	padding-bottom: 12px;
}

.right-sidebar .box .contentbox_item_image {
	width: 24px;
}

.right-sidebar .box.documents td:first-child {
	padding-right: 7px;
}

.right-sidebar .box.tools td:first-child img {
	margin-right: 5px;
}

.right-sidebar .box .staff-members-callout-wrapper h2:first-child {
	display: none;
}

@media (max-width: 991px) {
	.right-sidebar .box {
		padding-right: 0rem;
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.right-sidebar .box p,
	.right-sidebar .box li,
	.right-sidebar .box a {
		font-size: 1rem;
		line-height: 1.5rem;
	}
}

/****************** Footer ***********************/
/*************************************************/
.footer a,
.signoff a {
	border-bottom: 1px solid transparent;
}

#GRIPFooterLogo {
	position: absolute;
	right: 0;
	top: 0;
	line-height: 11px;
	margin-right: 1rem;
	padding: 0px !important;
}

#GRIPFooterLogoImg {
	margin-top: -20px !important;
}

.phone-link.mobile {
	display: none;
}

.phone-link.desktop {
	display: inline-block;
}

@media (max-width: 991px) {
	.phone-link.mobile {
		display: inline-block;
	}

	.phone-link.desktop {
		display: none;
	}

	#GRIPFooterLogo {
		position: static;
		display: block;
		margin-right: auto;
		margin-left: auto;
		float: none;
	}
}

/****************** Inside Page Styles ***************/
/*****************************************************/
body a {
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #006e51;
	line-height: 1.5rem;
	font-weight: 400;
	text-decoration: none;
	border-bottom: 1px dotted #a8a8a8;
}

body a:hover {
	border-bottom-color: transparent;
	color: #a8a8a8;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	color: inherit;
	border-bottom: 1px solid #006e51;
	text-decoration: none;
	line-height: inherit;
	font-weight: inherit;
	font-family: inherit;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
	border-bottom-color: transparent;
	color: #909090;
}

hr {
	margin-top: 2rem;
	margin-bottom: 1rem;
	border-bottom: 1px solid #5d5d5d;
	border-top: 0px;
}

/* buttons */
.inside-body-section .button,
.primary,
.inside-body-section button[type="submit"],
.inside-body-section .cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary {
	padding: 0.6rem 1rem 0.55rem;
	border-radius: 3px;
	background-color: #006e51;
	color: #fff;
	font-weight: 600;
	letter-spacing: 0.04rem;
	text-transform: uppercase;
	text-decoration: none;
	box-shadow: none;
	border: 0px;
}

.primary:hover,
.inside-body-section button[type="submit"]:hover,
.inside-body-section .cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover {
	background-color: #01503c;
	color: #fff;
	padding: 0.6rem 1rem 0.55rem;
}

.secondary,
.inside-body-section button[type="submit"].secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.inside-body-section .cms_form_button.secondary,
.inside-body-section .button.secondary {
	padding: 0.6rem 1rem 0.55rem;
	border-radius: 3px;
	background-color: #333;
	color: #fff;
	font-weight: 600;
	letter-spacing: 0.04rem;
	text-transform: uppercase;
	text-decoration: none;
	box-shadow: none;
	border: 0px;
}

.secondary:hover,
.inside-body-section button[type="submit"].secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.inside-body-section .cms_form_button.secondary:hover,
.inside-body-section .button.secondary:hover {
	background-color: #000;
	color: #fff;
	padding: 0.6rem 1rem 0.55rem;
}

.tertiary,
.inside-body-section button[type="submit"].tertiary,
.cms_form_button.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	padding: 0.6rem 1rem 0.55rem;
	border-radius: 3px;
	background-color: #333;
	color: #fff;
	font-weight: 600;
	letter-spacing: 0.04rem;
	text-transform: uppercase;
	text-decoration: none;
	box-shadow: none;
	border: 0px;
}

.tertiary:hover,
.inside-body-section button[type="submit"].tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover {
	background-color: #000;
	color: #fff;
	padding: 0.6rem 1rem 0.55rem;
}

/* messages */
#message.success {
	background-color: #adebb0;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	font-family: 'Titillium Web', sans-serif;
	color: #333;
	font-size: 1rem;
	line-height: 1.25rem;
	font-weight: 400;
}

#message.error {
	background-color: #f7e1e3;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	font-family: 'Titillium Web', sans-serif;
	color: #333;
	font-size: 1rem;
	line-height: 1.25rem;
	font-weight: 400;
}

#message.success *,
#message.error * {
	font-family: 'Titillium Web', sans-serif;
	color: #333;
	font-size: 1rem;
	line-height: 1.25rem;
	font-weight: 400;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

/* tables */
table.styled {
	background-color: white;
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid white;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #006e51;
	color: white;
	font-weight: normal;
}

table.styled th {
	font-weight: normal;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: darkgray;
	color: black;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: none;
}

table.styled th a:hover {
	color: inherit;
	font-weight: inherit;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

table.styled.striped tr:nth-child(even) {
	background: lightgray;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
input[type="search"],
select,
textarea {
	max-width: 100%;
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #666;
	font-size: 0.9rem;
	margin-bottom: 10px;
}

select {
	padding: 6px 12px;
}

textarea {
	resize: vertical;
}

label {
	font-weight: 400;
}

@media (max-width: 991px) {
	input[type="text"],
	input[type="tel"],
	input[type="password"],
	input[type="email"],
	select,
	textarea {
		width: 100%;
	}
}

/*blockquotes*/
blockquote p {
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
	color: inherit;
	font-family: inherit;
	font-weight: inherit;
}

/*pagination*/
.pagination-wrapper {
	font-size: 0.9rem;
}

/*skip to main content WCAG link*/
.cms_form_button.primary.skip-to-main-content.sronly {
	padding: 0px;
}

.cms_form_button.primary.skip-to-main-content.sronly:focus {
	text-indent: 0;
	width: auto;
	height: auto;
	box-shadow: 0 0 5px #000;
	z-index: 2000;
}


/***************** Content Modules  *******************/
/******************************************************/
/* jobs */
#jobs_module.cms_list .cms_list_item {
	margin-top: 0px;
}

#jobs_module.cms_list .cms_list_item .cms_title h3,
#jobs_module.cms_list .cms_list_item .cms_date h3 {
	margin-top: 0px;
	margin-bottom: 0px;
}

#jobs_module.cms_list .cms_list_item .cms_date h3 {
	font-size: 0.85rem;
}

#jobs_module.cms_list .cms_list_item .cms_footer {
	margin-top: 1.5rem;
	margin-bottom: 1.35rem;
}

#jobs_module.cms_entity .cms_content dt {
	font-weight: 500;
}

#jobs_module.cms_form .cms_label,
#jobs_module.cms_form .cms_field,
#jobs_module.cms_form .cms_required {
	background: none;
	font-weight: normal !important;
	vertical-align: top;
}

#jobs_module.cms_form .cms_divider {
	border: none;
}

#jobs_module.cms_form .cms_form_button {
	padding: 0.6rem 1rem 0.55rem;
}

@media (max-width: 991px) {
	#jobs_module.cms_form .cms_field input[type="text"],
	#jobs_module.cms_form .cms_field input[type="tel"],
	#jobs_module.cms_form .cms_field input[type="email"],
	#jobs_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#jobs_module.cms_form .cms_label,
	#jobs_module.cms_form .cms_required {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
		padding-top: 7px;
	}

	#jobs_module.cms_form .cms_required {
		width: 20px;
	}
}

/* locations */
#locations_module.list .locations_list_title,
#locations_module.list .location_types_title {
	border-color: #e9ece3;
}

#locations_module .location-extra-fields .extra-field .extra-field-label {
	font-weight: 500;
}

/* partners */
#partners_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0rem;
}

#partners_module.cms_list .cms_item_divider {
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

#partners_module.cms_list .cms_list_item table td.image {
	padding-right: 25px;
}

#partners_module.cms_list .cms_list_item table td.image a img {
	max-width: 150px;
}

#partners_module.cms_list .cms_list_item .footer {
	padding: 0px;
}

#partners_module.cms_entity .image {
	float: none;
	margin-right: 0px;
}

@media (max-width: 991px) {
	#partners_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#partners_module.cms_list .cms_list_item table td.image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/* staff */
#staffdirectory_module.cms_list .cms_list_item .cms_title {
	font-weight: 500;
}

#staffdirectory_module.cms_list .cms_list_item .image {
	padding-right: 15px;
	width: 150px;
}

#staffdirectory_module.cms_list .cms_list_item .image a img {
	max-width: 140px;
}

#staffdirectory_module.cms_list .cms_list_item .footer {
	padding: 0px;
}

@media (max-width: 991px) {
	#staffdirectory_module.cms_list .cms_list_item .image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}

	#staffdirectory_module.cms_entity .image {
		float: none;
		margin-right: 0px;
		display: block;
	}
}

/* submission forms */
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 1px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	margin: 0px;
	font-size: 0.9rem;
	line-height: normal;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

.module-forms.confirmation .step-title {
	margin-top: 1rem;
	color: #006e51;
	font-size: 1.4rem;
	line-height: 1.9rem;
	font-weight: 700;
}

.module-forms.confirmation .value-cell img.signature-image {
	max-width: 100%;
}

.module-forms.confirmation .label-cell {
	font-weight: normal;
}

/* search */
.search-field-context em {
	color: #000;
}

.search-collection .search-node .search-field {
	height: auto;
	width: 100%;
}

.search-collection .search-node {
	margin: 20px 0 0 0;
	padding: 20px 0 0 0;
	border-top: 1px solid rgba(152, 169, 182, 0.15);
}

/* datepicker */
#ui-datepicker-div {
	font-size: 0.9rem;
}

/* account */
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 256px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid #e9ece3;
	margin: 20px 0;
	padding-bottom: 7px;
	color: #006e51;
	font-size: 1.1rem;
	line-height: 1.3em;
	font-weight: 500;
	font-family: 'Open Sans', sans-serif;
}

#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ff0000;
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url("/core/public/shared/assets/images/required.gif");
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .form_item .field .login_field {
		max-width: 100%;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}


/***************** Content Boxes  *********************/
/******************************************************/
/* partners */
#partners_module.homepage_contentbox {
	text-align: left;
	padding: 20px;
	font-size: 1rem;
	line-height: 1.5rem;
}

#partners_module.homepage_contentbox a {
	color: #000000;
}

/***************** Products table  *********************/
/******************************************************/
.products-description-container {
	width: 100%;
	display: inline-block;
}

.products-description-container .video-container {
	float: left;
	margin-right: 15px;
}

.productstbl {
	width: 100%;
	display: inline-block;
}

.productstbl tbody {
	display: block;
	width: 100%;
}

.productstbl tr {
	display: block;
}

.productstbl tr td {
	width: 30%;
	display: block;
	margin-bottom: 1.5rem;
	float: left;
}

.productstbl tr td:nth-child(2) {
	margin-right: 5%;
	margin-left: 5%;
}

.productstbl tr td p {
	margin-bottom: 0.5rem;
}

.productstbl tr td p a {
	transition: all 200ms ease;
	color: #006e51;
	line-height: 1.5rem;
	font-weight: 400;
	text-decoration: none;
	border-bottom: 1px dotted #a8a8a8;
}

.productstbl tr td p a:hover {
	border-bottom-color: transparent;
	color: #a8a8a8;
}

.productstbl tr td p a img {
	max-width: 100%;
	height: auto;
}

.productstbl tr td p:nth-child(1) {
	margin-bottom: 1rem;
}

.productstbl tr td p:nth-child(2) {
	float: right;
	width: 50%;
	text-align: right;
}

.productstbl tr td p:nth-child(3) {
	float: left;
	width: 50%;
}

.productstbl tr td p:nth-child(1) a {
	border-bottom: none;
}

@media (max-width: 991px) {
	.products-description-container .video-container {
		display: block;
		float: none;
		margin-bottom: 10px;
	}

	.productstbl tr td p:nth-child(2),
	.productstbl tr td p:nth-child(3) {
		float: none;
		width: 100%;
		text-align: left;
	}
}

@media (max-width: 767px) {
	.productstbl tr td {
		width: 47%;
	}

	.productstbl tr td:nth-child(2) {
		margin-left: 0px;
		margin-right: 0px;
	}

	.productstbl tr:nth-child(odd) td:nth-child(odd) {
		margin-right: 3%;
		margin-left: 0%;
	}

	.productstbl tr:nth-child(odd) td:nth-child(even) {
		margin-right: 0%;
		margin-left: 3%;
	}

	.productstbl tr:nth-child(even) td:nth-child(odd) {
		margin-right: 0%;
		margin-left: 3%;
	}

	.productstbl tr:nth-child(even) td:nth-child(even) {
		margin-right: 3%;
		margin-left: 0%;
	}
}

@media (max-width: 479px) {
	.productstbl tr td,
	.productstbl tr:nth-child(odd) td:nth-child(odd),
	.productstbl tr:nth-child(odd) td:nth-child(even),
	.productstbl tr:nth-child(even) td:nth-child(odd),
	.productstbl tr:nth-child(even) td:nth-child(even) {
		width: 100%;
		margin-left: 0px;
		margin-right: 0px;
	}
}
