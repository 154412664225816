h1,
h2,
h3,
h4,
h5,
h6 {
	margin:0px;
	padding:0px;
	font-size:inherit;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	color:inherit;
	font-size:inherit;
	font-weight:inherit;
}

.cms_no_items {
	text-align:center;
}

.pagination-wrapper .pagination-row #pagination .center select {
    width: auto !important;
}

.section_separator {
	border-bottom: 1px solid #e1e3e0;
	line-height: 5px;
}

.contentbox_item {}

	.contentbox_item .contentbox_item_image {
		padding: 0px 5px 0px 0px;
	}

	.contentbox_item .contentbox_item_content {}


/* --- search facets --- */
.search-facets{
	width: 100%;
	float: left;
}

.search-facets .search-facet{
	width: 100%;
	padding: 10px 0;
	float: left;
}

.search-facets .clear-facet-link{
	width: 100%;
	float: left;
	clear: both;
}

.search-facets .search-facet .search-facet-filter-by{
	width: 100%;
}

.search-facets .search-facet .filter-checkbox{
	height: 30px;
	float: left;
	width: 100%;
}


.search-facets .search-facet .attribute-with-image{
	float: left;
	width: 24%;
	margin: 10px 1% 10px 0;
	height: 60px;
	text-align: center;
}

.search-facets .search-facet .attribute-with-image.selected-option img{
	border: 2px solid #222222;
	text-decoration: none;
}

.search-facets .search-facet .attribute-with-image img{
	max-width: none;
	border: 2px solid transparent;
}

.search-facets .search-facet .filter-checkbox-box{
	height: 18px;
	width: 18px;
	margin: 4px 0 0 0px;
	float: left;
}

.search-facets .search-facet .checkbox-label{
	line-height: 14px;
	display: inline-block;
	font-weight: normal;
	margin: 5px 20px 0 10px;
	font-size: 0.8em;
	cursor: pointer;
	float: left;
}

.search-facets .search-facet .price-range{
	float: left;
	margin-right: 2%;
	width: 28%;
}

.search-facets .search-facet .price-range-symbols{
	color: rgba(81, 99, 112, 0.49);
    float: left;
    height: 30px;
    margin-right: 2%;
}

.search-facets .search-facet .price-range-button a{
    background-color: #757575;
    float: left;
    font-size: 0.875rem;
    font-weight: bold;
    height: 30px;
    line-height: 25px;
    padding: 3px 5px;
    color: #ffffff;
    text-transform: uppercase;
    text-decoration: none;
}

.price-by-quantity-block {
    margin-bottom: 8px;
    margin-top: 8px;
}

.search-facet-options-list a.selected{
	font-weight: bold;
}

/* Faceted Search Breadcrumbs */
#faceted-search-breadcrumbs{
	float: left;
}

.filter-applied-wrap {
    border: 1px solid #cccccc;
    display: block;
    float: left;
    height: 30px;
    margin: 0 10px 10px 0px;
}
.filter-applied-x {
    background-image: url("/core/public/shared/assets/images/icon_x.svg");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: auto 10px;
    float: right;
    min-width: 0;
    opacity: 0.25;
    transition: background-color 150ms ease 0s, opacity 150ms ease 0s;
    width: 28px;
    height: 100%;
}
.filter-applied-x:hover {
    background-color: #dddddd;
    opacity: 1;
}
.filter-applied-label {
    float: left;
    font-size: 0.625rem;
    font-weight: normal;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 6px;
    text-align: center;
}
.filters-applied-title {
    color: #777777;
    font-size: 0.8em;
   	font-weight: bold;
    text-transform: uppercase;
    float: left;
    margin: 3px;
    width: 100%;
}
.filter-applied-clear-all {
    float: left;
    height: 30px;
}
.clear-all-x {
    background-image: url("/core/public/shared/assets/images/icon_x.svg");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: auto 10px;
    float: right;
    height: 100%;
    min-width: 36px;
    opacity: 0.5;
    width: 36px;
}
.clear-all {
	margin-top: 6px;
    float: left;
}

/* "Screen Reader only" class: use it to make an element present for screen reader users only. */
.sronly { 
	text-indent: 10px;
	display: block;
	height: 1px;
	overflow: hidden;
	position: absolute;
	width: 1px;
	z-index: -1;
}

/* "Block Formatting Context" class: use it on a container that has floated elements inside it, to prevent its siblings from wrapping around it. */
.bfc { 
	overflow: hidden;
}